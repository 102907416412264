
const init = () => {

    if(document.querySelectorAll('[data-tooltip-target]').length <= 0){ return false; }

    document.querySelectorAll('[data-tooltip-target]').forEach((el) => {
        el.addEventListener('mouseenter', (e) => {
            let tooltipRef = e.target.getAttribute('data-tooltip-target');
            let tooltip = document.querySelector(tooltipRef);

            tooltip.classList.remove('invisible', 'opacity-0');
        });

        el.addEventListener('mouseleave', (e) => {
            let tooltipRef = e.target.getAttribute('data-tooltip-target');
            let tooltip = document.querySelector(tooltipRef);

            tooltip.classList.add('invisible', 'opacity-0');
        });
    });

}

export default { init };