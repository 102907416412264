// Default theme
import Splide from '@splidejs/splide';
//import '@splidejs/splide/dist/css/splide.min.css';
import '@splidejs/splide/dist/css/splide-core.min.css';

const init = () => {

    if(document.querySelector('.splide')){
        new Splide( '.splide' ).mount();
    }
};

export default { init };