

import "../styles/main.scss";

import './parts/_scrollEvents';
import menu from './parts/_menu.js';
import accordion from './parts/accordion.js';
import slider from './parts/_sliders';
import filters from './parts/filters';
import tooltip from './parts/tooltip';

menu.init();
accordion.init();
slider.init();
filters.init();
tooltip.init();