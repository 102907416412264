import Shuffle from 'shufflejs';

const init = () => {

    const filtersSection = document.querySelector('.items-to-filter');

    const resizeItems = () => {
        let Wwidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

        if(Wwidth <= 768){return false;}
        
        let h = 0;
        let elems = document.querySelectorAll('.filter-item');
        elems.forEach(el => {
            el.style.height = 'auto';
            h = el.offsetHeight > h ? el.offsetHeight : h;
        });

        elems.forEach(el => {
            el.style.height = h + 'px';
        });

    }

    if(filtersSection){
        const shuffleInstance = new Shuffle(filtersSection, {
            itemSelector: '.filter-item',
            buffer: 1,
            sizer: document.querySelector('.js-shuffle-sizer')
        });

        document.querySelectorAll('.filters a[data-group]').forEach((filter) => {

            filter.addEventListener('click', () => {
                let filterVal = filter.dataset.group;
                shuffleInstance.filter(filterVal);

                document.querySelectorAll('.filters a').forEach((item) => item.classList.remove('current'));
                filter.classList.add('current');
            });
        });
        
        if(document.querySelector('.items-to-filter').classList.contains('sameHeight')){
            shuffleInstance.on(Shuffle.EventType.LAYOUT, () => {
                
                resizeItems();
            });
        }

        resizeItems();
        
    }
}

export default { init };