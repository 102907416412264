
const init = () => {

    let menuId = "mobile-menu";

    let menu = document.querySelector(`#${menuId}`);
    let toggleMenu = document.querySelector(`[data-collapse-toggle="${menuId}"]`);
    let toggleMenuIcon = document.querySelectorAll(`[data-toggle-icon="${menuId}"]`);
    let bgMenu = document.querySelector(`[data-bg-menu="${menuId}"]`);

    toggleMenu.addEventListener('click', () => {
        menu.classList.toggle('translate-x-full');
        toggleMenuIcon.forEach(el => el.classList.toggle('hidden'));
        document.body.classList.toggle('mobileMenuIsOpen');
        bgMenu.classList.toggle('opacity-0');
    });

    document.querySelectorAll('.menu-item-has-children').forEach(el => el.addEventListener('click', () => {
        el.classList.toggle('active');
    }));

};

export default { init };