const open = (item) => {
    let accordionItemH = item.querySelector(`.accordion-body`).offsetHeight;
    item.querySelector(`.accordion-content`).style.maxHeight = `${accordionItemH}px`;
    item.classList.add('open');
}

const close = (item) => {
    item.querySelector(`.accordion-content`).style.maxHeight = `0px`;
    item.classList.remove('open');
}

const init = () => {
    const accordionItems = document.querySelectorAll(".accordion-item");

    accordionItems.forEach((accordionItem) => {
        
        accordionItem.addEventListener("click", function () {
            let thisParent = this.getAttribute('data-parent');
            let thisTarget = this.getAttribute('data-target');

            document.querySelectorAll(`.accordion#${thisParent} .accordion-item`).forEach((item) => {
            
                if(thisTarget == item.getAttribute('data-target')){
                    return;
                }
                
                if(item.classList.contains('open')){
                    close(item);
                }
            });

            if(this.classList.contains('open')){
                close(this);
            }else{
                open(this);
            }
        
        });
    });

}

export default { init };